export const ADD_TO_CART = (state, { product, quantity }) => {

    let productInCart = state.cart.find(item => {
        return item.product.products.id === product.products.id;
    });

    var tax=0;
    var price=(product.products.product_price);
    var tax_type=(product.products.product_tax_type);
    var tax_percentage=(product.products.product_order_tax);

    if(tax_type==2)
    {
        tax= (price*tax_percentage)/100;
    }

    if (productInCart) {
        productInCart.quantity += quantity;
        productInCart.sub_total = tax+(price*productInCart.quantity)-productInCart.product_discount_amount;
        return;
    }

    state.cart.push({
        product,
        quantity,
        product_discount_amount:0,
        product_tax_amount:tax,
        sub_total:tax+(price*quantity)
    })
}

export const REMOVE_PRODUCT_FROM_CART = (state, product) => {
    state.cart = state.cart.filter(item => {
        return item.product.products.id !== product.product.products.id;
    })
}

export const CHECK_PRODUCT_EXISTS = (state, product) => {
    state.currentProduct = state.cart.find(item => {
        return item.product.products.id === product.product.products.id;
    })
}

export const INCREMENT_TO_CART = (state, { product }) => {
    let productInCart = state.cart.find(item => {
        return item.product.products.id === product.products.id;
    });

    var tax=0;
    var price=(product.products.product_price);
    var tax_type=(product.products.product_tax_type);
    var tax_percentage=(product.products.product_order_tax);

    if(tax_type==2)
    {
        tax= (price*tax_percentage)/100;
    }

    if (productInCart) {
        productInCart.quantity = productInCart.quantity +1;
        productInCart.sub_total = tax+(price*productInCart.quantity)-productInCart.product_discount_amount;
        return;
    }

}

export const DECREMENT_TO_CART = (state, { product }) => {

    let productInCart = state.cart.find(item => {
        return item.product.products.id === product.products.id;
    });

    var tax=0;
    var price=(product.products.product_price);
    var tax_type=(product.products.product_tax_type);
    var tax_percentage=(product.products.product_order_tax);

    if(tax_type==2)
    {
        tax= (price*tax_percentage)/100;
    }

    if (productInCart) {
        if(productInCart.quantity-1 === 0)
        {
            state.cart = state.cart.filter(item => {
                return item.product.products.id !== product.products.id;
            })
        }
        else
        {
            productInCart.quantity = productInCart.quantity -1;
            productInCart.sub_total = tax+(price*productInCart.quantity)-productInCart.product_discount_amount;
            return;
        }
    }
}

export const ADD_ORDER_TAX_PERCENTAGE = (state, { value }) => {
   state.orderTaxPercentage=value;
}

export const ADD_DISCOUNT_PERCENTAGE = (state, { value }) => {
    state.discountPercentage=value;
}

 export const ADD_SHIPPING = (state, { value }) => {
    state.shipping=value;
 }

export const CLEAR_CART_ITEMS = (state) => {
    state.cart = [];
    state.currentProduct-[];
    state.orderTaxPercentage=0;
    state.discountPercentage=0; 
    state.shipping=0;
}

export const SET_CART = (state, cartItems) => {
    state.cart = cartItems;
}
