export const cartItemCount = (state) => {
    return state.cart.length;
}

export const cartTotalPrice = (state) => {
    let total = 0;

    state.cart.forEach(item => {
        total += item.sub_total;
    })
    
    let discountAmount=(state.discountPercentage>0)?parseFloat(state.discountPercentage)*parseFloat(total)/100:0;
    let orderTaxAmount=(state.orderTaxPercentage>0)?parseFloat(state.orderTaxPercentage)*parseFloat(total)/100:0;
    let grandTotal=parseFloat(state.shipping)+parseFloat(total)+parseFloat(orderTaxAmount)-parseFloat(discountAmount);
    return grandTotal;
}


export const discountAmount = (state) => {
    let total = 0;

    state.cart.forEach(item => {
        total += item.sub_total;
    })

    let discountAmount=(state.discountPercentage>0)?parseFloat(state.discountPercentage)*parseFloat(total)/100:0;
    return discountAmount;
}

export const orderTaxAmount = (state) => {
    let total = 0;

    state.cart.forEach(item => {
        total += item.sub_total;
    })

    let orderTaxAmount=(state.orderTaxPercentage>0)?parseFloat(state.orderTaxPercentage)*parseFloat(total)/100:0;
    return orderTaxAmount;
}