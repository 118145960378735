export const addProductToCart = ({ commit }, { product, quantity }) => {
    commit('ADD_TO_CART', { product, quantity });
}

export const increaseQuantityFromCart = ({ commit }, product) => {
    commit('INCREMENT_TO_CART', product);
}

export const decreaseQuantityFromCart = ({ commit }, product) => {
    commit('DECREMENT_TO_CART', product);
}

export const removeProductFromCart = ({ commit }, product) => {
    commit('REMOVE_PRODUCT_FROM_CART', product);
}

export const checkProductExists = ({ commit }, product) => {
    commit('CHECK_PRODUCT_EXISTS', product);
}

export const clearCartItems = ({ commit }) => {
    commit('CLEAR_CART_ITEMS');
}

export const updateOrderTaxPer = ({ commit }, value) => {
    commit('ADD_ORDER_TAX_PERCENTAGE', value);
}

export const updateDiscountPer = ({ commit }, value) => {
    commit('ADD_DISCOUNT_PERCENTAGE', value);
}

export const updateShipping = ({ commit }, value) => {
    commit('ADD_SHIPPING', value);
}