import axios from "axios";

export default {
  namespaced: true,

  state: {
    userData: null
  },

  getters: {
    user: state => state.userData
  },

  mutations: {
    setUserData(state, user) {
      state.userData = user;
    }
  },

  actions: {
    getUserData({ commit }) {
      axios
        .get(process.env.VUE_APP_API_URL + "user")
        .then(response => {
          commit("setUserData", response.data);
        })
        .catch(() => {
          localStorage.removeItem("authToken");
        });
    },
    sendLoginRequest({ commit }, data) {
      commit("setErrors", {}, { root: true });
      return axios
        .post(process.env.VUE_APP_API_URL + "auth/signin", data)
        .then(response => {
          if(response.data.statusCode === 200)
          {
            commit("setUserData", response.data.data.user);
            localStorage.setItem("authToken", response.data.data.token);
          }
          return response;
        })
        .catch(error => {
          if(error.response.status === 500)
          {
            alert(error.response.data.message);
          }
        });
    },
    sendRegisterRequest({ commit }, data) {
      commit("setErrors", {}, { root: true });
      return axios
        .post(process.env.VUE_APP_API_URL + "auth/signup", data)
        .then(response => {
          if(response.data.statusCode === 200)
          {
            commit("setUserData", response.data.user);
            localStorage.setItem("authToken", response.data.token);
          }
        return response;
        })
        .catch(error => {
          if(error.response.status === 500)
          {
            alert(error.response.data.message);
          }
        });
    },
    sendResetPasswordRequest({ commit }, data) {
      commit("setErrors", {}, { root: true });
      return axios
        .post(process.env.VUE_APP_API_URL + "requestReset" , data)
        .then(response => {
          localStorage.setItem("verifyEmail",data.email );
          return response;
        });
    },
    codeVerify({ commit }, data) {
      commit("setErrors", {}, { root: true });
      return axios
        .post(process.env.VUE_APP_API_URL + "codeVerfy" , data)
        .then(response => {
          localStorage.setItem("verifiedCode",data.code );
          return response;
        });
    },
    changePassword({ commit }, data) {
      commit("setErrors", {}, { root: true });
      return axios
        .post(process.env.VUE_APP_API_URL + "passwordReset", data)
        .then(response => {
          localStorage.removeItem("verifyEmail");
          localStorage.removeItem("verifiedCode");
          return response;
        });
    },
    verifyEmail({ commit }, data) {
      commit("setErrors", {}, { root: true });
      return axios
        .get(process.env.VUE_APP_API_URL + `verifyEmail?code=${data.code}&email=${data.email}`)
        .then(response => {
          return response;
        });
    },
    sendLogoutRequest({ commit }) {
      return axios.post(process.env.VUE_APP_API_URL + "auth/signout")
      .then(response => {
        if(response.data.statusCode === 200)
        {
          console.log('asd');
          commit("setUserData", null);
          localStorage.removeItem("authToken");
          this.$router.push({ name: "Login" });
        }
      return response;
      });
    },
    sendVerifyResendRequest() {
      return axios.get(process.env.VUE_APP_API_URL + "email/resend");
    },
    sendVerifyRequest({ dispatch }, hash) {
      return axios
        .get(process.env.VUE_APP_API_URL + "email/verify/" + hash)
        .then(() => {
          dispatch("getUserData");
        });
    }
  }
};
