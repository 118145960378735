import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";

import "./assets/fonts/Lato.css";

import "./assets/newcss/variables.css";
import "./assets/newcss/typo.css";
import "./assets/newcss/auth.css";
import "./assets/newcss/leftsidebar.css";
import "./assets/newcss/navbar.css";
import "./assets/newcss/metal.css";

import "./assets/css/main.css";
import "./assets/css/mystyle.css";
import "./assets/css/newstyle.css";
import "./assets/css/topnavigation.css";
import "./assets/css/order.css";
import "./assets/css/responsive.css";

import Vuelidate from "vuelidate";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import VueSignaturePad from "vue-signature-pad";
import VueSimpleAlert from "vue-simple-alert";
import VueContentPlaceholders from "vue-content-placeholders";
import vSelect from "vue-select";
import "boxicons";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

const toastOptions = {
  transition: "Vue-Toastification__bounce",
  maxToasts: 1,
  newestOnTop: true,
};

Vue.use(Toast, toastOptions);
Vue.component("v-select", vSelect);
Vue.use(VueSimpleAlert);
Vue.use(VueSignaturePad);
Vue.use(VueContentPlaceholders);
const options = {
  confirmButtonColor: "#41b882",
  cancelButtonColor: "#ff7674",
};

import "vue-select/dist/vue-select.css";

Vue.use(VueSweetalert2, options);
Vue.use(Loading);
Vue.use(Vuelidate);
Vue.use(require('vue-moment'));

Vue.config.productionTip = false;
Vue.prototype.$customerStorageURL =
  process.env.VUE_APP_API_URL_CUSTOMER_STORAGE;
Vue.prototype.$supplierStorageURL =
  process.env.VUE_APP_API_URL_SUPPLIER_STORAGE;
Vue.prototype.$manufacturerStorageURL =
  process.env.VUE_APP_API_URL_MANUFACTUERER_STORAGE;
Vue.prototype.$productStorageURL = process.env.VUE_APP_API_URL_PRODUCT_STORAGE;
Vue.prototype.$currency = "£";
Vue.prototype.$process2StorageURL =
  process.env.VUE_APP_API_URL_PRODUCT_PROCESS2;
Vue.prototype.$shapeStorageURL =
  process.env.VUE_APP_API_URL_PRODUCT_SHAPE;
Vue.prototype.$gemstonesStorageURL =
  process.env.VUE_APP_API_URL_PRODUCT_GEMSTONE;
Vue.prototype.$metalStorageURL =
  process.env.VUE_APP_API_URL_PRODUCT_METAL;
Vue.prototype.$settingtypeStorageURL =
  process.env.VUE_APP_API_URL_PRODUCT_SETTINGTYPE;
Vue.prototype.$companyLogo =
  process.env.VUE_APP_API_URL_COMPANY_LOGO;
Vue.prototype.$userAvatar =
  process.env.VUE_APP_API_URL_USER_AVATAR;

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    // console.log(error.response.status);
    if (error.response.status === 401) {
      store.commit("auth/setUserData", null);
      localStorage.removeItem("authToken");
      router.push({ name: "Login" });
    }
    // store.commit("auth/setUserData", null);
    // localStorage.removeItem("authToken");
    // router.push({ name: "Login" });
  }
);

Vue.directive("click-outside", {
  bind(el, binding, vnode) {
    el.clickOutsideEvent = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unbind(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});

axios.interceptors.request.use(function(config) {
  config.headers.common = {
    Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  return config;
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
