import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const guest = (to, from, next) => {
  if (!localStorage.getItem("authToken")) {
    localStorage.removeItem("authToken");
    return next(next);
  } else {
    return next("/");
  }
};

const auth = (to, from, next) => {
  if (localStorage.getItem("authToken")) {
    return next();
  } else {
    localStorage.removeItem("authToken");
    return next("/login");
  }
};

const routes = [
  {
    path: "/",
    name: "Home",
    beforeEnter: auth,
    component: () => import(/* webpackChunkName: "Home" */ "../views/Home.vue"),
  },
  {
    path: "/login",
    name: "Login",
    beforeEnter: guest,
    component: () =>
      import(/* webpackChunkName: "Login" */ "../views/Auth/Login.vue"),
  },
  {
    path: "/register",
    name: "Register",
    beforeEnter: guest,
    component: () =>
      import(/* webpackChunkName: "Register" */ "../views/Auth/Register.vue"),
  },
  {
    path: "/verify/:hash",
    name: "Verify",
    beforeEnter: auth,
    props: true,
    component: () =>
      import(/* webpackChunkName: "Verify" */ "../views/Auth/Verify.vue"),
  },
  {
    path: "/forgot-password",
    name: "Forgot",
    beforeEnter: guest,
    component: () =>
      import(
        /* webpackChunkName: "Forgot" */ "../views/Auth/ForgotPassword/Forgot.vue"
      ),
  },
  {
    path: "/verify-email",
    name: "VerifyEmail",
    beforeEnter: guest,
    component: () =>
      import(
        /* webpackChunkName: "VerifyEmail" */ "../views/Auth/ForgotPassword/VerifyEmail.vue"
      ),
  },
  {
    path: "/new-password",
    name: "NewPassword",
    beforeEnter: guest,
    component: () =>
      import(
        /* webpackChunkName: "NewPassword" */ "../views/Auth/ForgotPassword/NewPassword.vue"
      ),
  },
  {
    path: "/password-update",
    name: "PasswordUpdate",
    beforeEnter: guest,
    component: () =>
      import(
        /* webpackChunkName: "PasswordUpdate" */ "../views/Auth/ForgotPassword/PasswordUpdate.vue"
      ),
  },
  {
    path: "/customers",
    name: "Customers",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Customers" */ "../views/Customers.vue"),
  },
  {
    path: "/suppliers",
    name: "Suppliers",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Suppliers" */ "../views/Suppliers.vue"),
  },
  {
    path: "/manufacturers",
    name: "Manufacturers",
    beforeEnter: auth,
    component: () =>
      import(
        /* webpackChunkName: "Manufacturers" */ "../views/Manufacturers.vue"
      ),
  },
  {
    path: "/add-customer",
    name: "CreateCustomer",
    beforeEnter: auth,
    component: () =>
      import(
        /* webpackChunkName: "CreateCustomer" */ "../views/CreateCustomer.vue"
      ),
  },
  {
    path: "/add-manufacturer",
    name: "CreateManufacturer",
    beforeEnter: auth,
    component: () =>
      import(
        /* webpackChunkName: "CreateManufacturer" */ "../views/CreateManufacturer.vue"
      ),
  },
  {
    path: "/add-supplier",
    name: "CreateSupplier",
    beforeEnter: auth,
    component: () =>
      import(
        /* webpackChunkName: "CreateSupplier" */ "../views/CreateSupplier.vue"
      ),
  },
  {
    path: "/Order",
    name: "OrderPage",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "OrderPage" */ "../views/OrderPage.vue"),
  },
  {
    path: "/categories",
    name: "Categories",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Categories" */ "../views/Categories.vue"),
  },
  {
    path: "/products",
    name: "Products",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Products" */ "../views/Products.vue"),
  },
  {
    path: "/add-products",
    name: "AddProducts",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "AddProducts" */ "../views/AddProducts.vue"),
  },
  {
    path: "/edit-products",
    name: "EditProducts",
    beforeEnter: auth,
    component: () =>
      import(
        /* webpackChunkName: "EditProducts" */ "../views/EditProducts.vue"
      ),
  },
  {
    path: "/view-products",
    name: "ViewProducts",
    beforeEnter: auth,
    component: () =>
      import(
        /* webpackChunkName: "ViewProducts" */ "../views/ViewProducts.vue"
      ),
  },
  {
    path: "/payment-payable",
    name: "PaymentPayable",
    beforeEnter: auth,
    component: () =>
      import(
        /* webpackChunkName: "PaymentPayable" */ "../views/PaymentPayable.vue"
      ),
  },
  {
    path: "/edit-payment-payable/:id?",
    name: "EditPaymentPayable",
    beforeEnter: auth,
    component: () =>
      import(
        /* webpackChunkName: "PaymentPayable" */ "../views/editPaymentPayable.vue"
      ),
  },
  {
    path: "/create-payment-payable",
    name: "CreatePaymentPayable",
    beforeEnter: auth,
    component: () =>
      import(
        /* webpackChunkName: "CreatePaymentPayable" */ "../views/CreatePaymentPayable.vue"
      ),
  },
  {
    path: "/show-payment-payable/:id?",
    name: "ShowPayments",
    beforeEnter: auth,
    component: () =>
      import(
        /* webpackChunkName: "ShowPayments" */ "../views/ShowPayments.vue"
      ),
  },
  {
    path: "/payment-payable-detail/:id?",
    name: "PaymentPayableDetail",
    beforeEnter: auth,
    component: () =>
      import(
        /* webpackChunkName: "PaymentPayableDetail" */ "../views/PaymentPayableDetail.vue"
      ),
  },
// ---------------------------------------------------------
  {
    path: "/payment-receivable",
    name: "PaymentReceivable",
    beforeEnter: auth,
    component: () =>
      import(
        /* webpackChunkName: "PaymentReceivable" */ "../views/PaymentReceivable.vue"
      ),
  },
  {
    path: "/edit-payment-receivable/:id?",
    name: "EditPaymentReceivable",
    beforeEnter: auth,
    component: () =>
      import(
        /* webpackChunkName: "PaymentReceivable" */ "../views/editPaymentReceivable.vue"
      ),
  },
  {
    path: "/create-payment-receivable",
    name: "CreatePaymentReceivable",
    beforeEnter: auth,
    component: () =>
      import(
        /* webpackChunkName: "CreatePaymentReceivable" */ "../views/CreatePaymentReceivable.vue"
      ),
  },
  {
    path: "/show-payment-receivable/:id?",
    name: "ShowPaymentsReceivable",
    beforeEnter: auth,
    component: () =>
      import(
        /* webpackChunkName: "ShowPayments" */ "../views/ShowPaymentsReceivable.vue"
      ),
  },
  {
    path: "/payment-receivable-detail/:id?",
    name: "PaymentReceivableDetail",
    beforeEnter: auth,
    component: () =>
      import(
        /* webpackChunkName: "PaymentReceivableDetail" */ "../views/PaymentReceivableDetail.vue"
      ),
  },
  {
    path: "/profile",
    name: "Profile",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Profile" */ "../views/Profile.vue"),
  },
  {
    path: "/configs",
    name: "Configuration",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Profile" */ "../views/configuration.vue"),
  },

  {
    path: "/debit-note",
    name: "DeditNote",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "DebitNote" */ "../views/DebitNote.vue"),
  },
  {
    path: "/balance-statment",
    name: "BalanceStatment",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "DebitNote" */ "../views/BalanceStatment.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
