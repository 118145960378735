export default {
    namespaced: true,
  
    state: {
      productsList: [],
    },
  
    getters: {
        productsList: state => state.productsList
    },
  
    mutations: {
        setProuctData(state, product) {
            state.productsList = product;
        },
    },
  
    actions: {
        setProuctData({commit}, data) {
            commit("setProuctData", data);
        },
    }
  };
  